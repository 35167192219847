import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useSelector } from 'react-redux';
import { shopNavLinks } from '../shopNavLinks';
import { createLink } from '../../../components/Linker/PathCreators';
import { enumTypesLinks } from '../../../components/Linker/typesLinks';
import { getShopId } from '../../../store/System/selectors';
import ShopRoutes from './ShopRoutes';
import { getCrudItem } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { SHOP_TYPE } from '../../../store/Crud/types/shop';
import { useAppDispatch } from '../../../store';
import { get } from '../../../store/Crud';

const ShopMenu = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const shopId: number = useSelector(getShopId) as any;
    const shop = useSelector(getCrudItem(resource.SHOP));

    const [isCollapsed, setIsCollapsed] = useState(false);
    const isNewShop = shopId.toString() === 'new';

    const onClick = (item: MenuInfo) => {
        const pathKey: enumTypesLinks = item.key as any;
        history.push(createLink(enumTypesLinks[pathKey], shopId.toString()));
    };

    useEffect(() => {
        if (!isNewShop && !shop) {
            dispatch(get(resource.SHOP, String(shopId))());
        }
    }, [dispatch, isNewShop, shop, shopId]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout.Sider width={230} theme="light" collapsible collapsed={isCollapsed} onCollapse={setIsCollapsed}>
                <Menu theme="light" defaultSelectedKeys={['1']} mode="inline" className="left_menu" onClick={onClick}>
                    {shopNavLinks.map((navLink) => {
                        const IconComponent = navLink.icon;

                        if (
                            navLink.key === enumTypesLinks.shopMarketplaceSettingList &&
                            shop?.type !== SHOP_TYPE.MARKETPLACE
                        ) {
                            return null;
                        }

                        return (
                            <Menu.Item key={navLink.key} disabled={navLink.canDisabled && isNewShop}>
                                <IconComponent />
                                <span>{navLink.title}</span>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </Layout.Sider>
            <ShopRoutes />
        </Layout>
    );
};

export default ShopMenu;
