import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';
import { IApiUser } from '../../../store/Crud/types/apiUser';

interface IApiUserList {
    shopId: number;
    onClick: (arg: IApiUser) => void;
}

const ApiUserList: FC<IApiUserList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();
    const apiUser = useSelector(getCrudItems(resource.API_USER));

    useEffect(() => {
        dispatch(find(resource.API_USER, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '25%',
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={apiUser}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default ApiUserList;
