import React, { FC, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { resource } from '../../../utils/constants';
import { IObject, ObjectView } from '../../../components/ObjectView';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { IShopPayment } from '../../../store/Crud/types/shopPayment';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { getCrudItems } from '../../../store/Crud/selectors';

interface IShopPaymentList {
    shopId: number;
    onClick: (arg: IShopPayment) => void;
}

const ShopPaymentList: FC<IShopPaymentList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const shopPaymentMethods = useSelector(getCrudItems(resource.SHOP_PAYMENT));

    useEffect(() => {
        dispatch(find(resource.SHOP_PAYMENT, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '25%',
        },
        {
            title: t(enumFieldName.active),
            dataIndex: 'active',
            key: 'active',
            width: '5%',
            render: (item: boolean) => (item ? 'Да' : 'Нет'),
        },
        {
            title: t(enumFieldName.setting),
            dataIndex: 'setting',
            key: 'setting',
            width: '30%',
            render: (setting: IObject) => <ObjectView object={setting} />,
        },
        {
            title: t(enumFieldName.paymentIntegrationId),
            dataIndex: 'paymentIntegrationId',
            key: 'paymentIntegrationId',
            width: '5%',
            render: (paymentIntegrationId: number) => `#${paymentIntegrationId}`,
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={shopPaymentMethods}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default ShopPaymentList;
