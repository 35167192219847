import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import { getCrudItem } from '../../../store/Crud/selectors';

interface IProfileEdit {
    itemId: string;
}

const ProfileEdit: FC<IProfileEdit> = ({ itemId }) => {
    const fields = {};
    const { id: profileId } = useParams<{ id: string }>();

    const profile = useSelector(getCrudItem(resource.PROFILE));

    const item = (profile && profile?.id === itemId) || profile?.id === Number(profileId) ? profile : {};

    return (
        <div>
            <ItemEdit item={item} fields={fields} deletable={false} saveable={false} />
        </div>
    );
};

export default ProfileEdit;
