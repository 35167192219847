import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import FileEdit from './components/FileEdit';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createFileListLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';

const FileEditPage = () => {
    const history = useHistory();
    const { id: fileId } = useParams<{ id: string }>();

    const shopId: number = useSelector(getShopId) as any;
    const shopFile = useSelector(getCrudItem(resource.FILE));

    const getTitle = () => {
        if (!shopFile) {
            return '';
        }

        return shopFile?.title ? shopFile.title : `#${shopFile.id}`;
    };

    const goToList = () => {
        history.push(createFileListLink(String(shopId)));
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.fileList}>Файлы</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <FileEdit shopId={shopId} itemId={fileId} onRemove={goToList} />
            </div>
        </div>
    );
};

export default FileEditPage;
