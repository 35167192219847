import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import DictionaryEdit from './components/DictionaryEdit';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createDictionaryLink } from '../../components/Linker/PathCreators';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { getShopId } from '../../store/System/selectors';
import { IDictionary } from '../../store/Crud/types/dictionary';

const DictionaryEditPage = () => {
    const history = useHistory();

    const dictionary = useSelector(getCrudItem(resource.DICTIONARY));
    const shopId: number = useSelector(getShopId) as any;
    const { id: dictionaryId } = useParams<{ id: string }>();

    const actionAfterSubmit = (item: IDictionary) => {
        if (item) {
            history.push(createDictionaryLink(String(shopId), String(item.id)));
        }
    };

    const getTitle = () => {
        if (dictionaryId === 'new') {
            return 'Новый';
        }
        return dictionary ? dictionary?.title : '';
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.dictionaryList}>Справочники</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <DictionaryEdit shopId={shopId} itemId={dictionaryId} actionAfterSubmit={actionAfterSubmit} />
            </div>
        </div>
    );
};

export default DictionaryEditPage;
