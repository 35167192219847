import React, { FC, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { resource } from '../../../utils/constants';
import { IObject, ObjectView } from '../../../components/ObjectView';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';
import { getCrudItems } from '../../../store/Crud/selectors';
import { IShopDelivery } from '../../../store/Crud/types/delivery';

interface IShopDeliveryList {
    shopId: string;
    onClick: (delivery: IShopDelivery) => void;
}

const ShopDeliveryList: FC<IShopDeliveryList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const shopDeliveries = useSelector(getCrudItems(resource.SHOP_DELIVERY));

    useEffect(() => {
        dispatch(find(resource.SHOP_DELIVERY, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '25%',
        },
        {
            title: t(enumFieldName.active),
            dataIndex: 'active',
            key: 'active',
            width: '5%',
            render: (item: boolean) => (item ? 'Да' : 'Нет'),
        },
        {
            title: t(enumFieldName.setting),
            dataIndex: 'setting',
            key: 'setting',
            width: '30%',
            render: (setting: IObject) => <ObjectView object={setting} />,
        },
        {
            title: t(enumFieldName.deliveryIntegrationId),
            dataIndex: 'deliveryIntegrationId',
            key: 'deliveryIntegrationId',
            width: '5%',
            render: (deliveryIntegrationId: string) => `#${deliveryIntegrationId}`,
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={shopDeliveries}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default ShopDeliveryList;
