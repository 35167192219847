import React, { useEffect } from 'react';
import { Breadcrumb, Table } from 'antd';
import { useSelector } from 'react-redux';
import { enumFieldName, t } from '../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../store';
import { getCrudItems } from '../../store/Crud/selectors';
import { enumResources } from '../../utils/constants';
import { find } from '../../store/Crud';
import { IClaimIntegration } from '../../store/Crud/types/integrations';

const ClaimIntegrationListPage = () => {
    const dispatch = useAppDispatch();
    const notifications: IClaimIntegration[] = useSelector(getCrudItems(enumResources.CLAIM_INTEGRATION));

    useEffect(() => {
        dispatch(find(enumResources.CLAIM_INTEGRATION)());
    }, [dispatch]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '10%',
        },
    ];
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Настройки заявок</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <Table rowKey="id" dataSource={notifications} columns={columns} />
            </div>
        </div>
    );
};

export default ClaimIntegrationListPage;
