import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import ShopPaymentEdit from './components/ShopPaymentEdit';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createShopPaymentLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { IShopPayment } from '../../store/Crud/types/shopPayment';

const ShopPaymentEditPage = () => {
    const history = useHistory();
    const { id: shopPaymentId } = useParams<{ id: string }>();

    const shopId: number = useSelector(getShopId) as any;
    const shopPayment = useSelector(getCrudItem(resource.SHOP_PAYMENT));

    const actionAfterSave = (itemShopPayment: IShopPayment) => {
        if (itemShopPayment) {
            history.push(createShopPaymentLink(String(shopId), String(itemShopPayment.id)));
        }
    };

    const getTitle = () => {
        if (shopPaymentId === 'new') {
            return 'Новая';
        }
        return shopPayment ? `#${shopPayment.id} ${shopPayment.title}` : '';
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.shopPaymentList}>Способы оплаты</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <ShopPaymentEdit shopId={shopId} itemId={shopPaymentId} actionAfterSave={actionAfterSave} />
            </div>
        </div>
    );
};

export default ShopPaymentEditPage;
