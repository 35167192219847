import React from 'react';
import { Breadcrumb } from 'antd';
import PaymentIntegrationList from './components/PaymentIntegrationList';

const PaymentIntegrationListPage = () => (
    <div>
        <Breadcrumb>
            <Breadcrumb.Item>Способы оплаты</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
            <PaymentIntegrationList />
        </div>
    </div>
);

export default PaymentIntegrationListPage;
