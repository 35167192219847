import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { formItemLayout, resource, tailFormItemLayout } from '../../../utils/constants';
import IntegrationFields from '../../../components/ItemEdit/components/IntegrationFields';
import Select from '../../../components/ItemEdit/components/Select';
import ErrorMessage from '../../../components/ItemEdit/components/ErrorMessage';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { create, find, get, update } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { getCrudItem, getCrudItems } from '../../../store/Crud/selectors';
import { IShopDelivery } from '../../../store/Crud/types/delivery';
import { createShopDeliveryLink } from '../../../components/Linker/PathCreators';
import { IDeliveryIntegration } from '../../../store/Crud/types';

interface IShopDeliveryEdit {
    itemId: string;
    shopId: number;
}

const ShopDeliveryEdit: FC<IShopDeliveryEdit> = ({ itemId, shopId }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const deliveryIntegrations: IDeliveryIntegration[] = useSelector(
        getCrudItems(resource.DELIVERY_INTEGRATION),
    ) as any;
    const shopDelivery = useSelector(getCrudItem(resource.SHOP_DELIVERY));

    const [deliveryIntegrationId, setDeliveryIntegrationId] = useState(null);
    const [currentId, setCurrentId] = useState<string>();
    const [error, setError] = useState<{ code: string }>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (currentId !== 'new') {
            form.resetFields();
        }

        if (currentId !== itemId) {
            if (itemId !== 'new') {
                dispatch(get(resource.SHOP_DELIVERY, itemId, { shopId })());
            }
            dispatch(find(resource.DELIVERY_INTEGRATION)());
            setCurrentId(itemId);
        }
    }, [currentId, dispatch, form, itemId, shopId]);

    const onSave = async (item: IShopDelivery) => {
        setIsSubmitting(true);
        if (item.id) {
            const { payload } = await dispatch(update(resource.SHOP_DELIVERY, String(item.id), item)());
            if (payload?.error?.code) {
                setError(payload?.error);
            } else {
                setError(undefined);
            }
        } else {
            const { payload } = await dispatch(create(resource.SHOP_DELIVERY, item)());
            if (payload.data) {
                history.push(createShopDeliveryLink(String(shopId), payload.data.id));
            }
            if (payload?.error?.code) {
                setError(payload?.error);
            }
        }
        setIsSubmitting(false);
    };

    const currentShopDelivery =
        shopDelivery && String(shopDelivery.id) === itemId
            ? shopDelivery
            : {
                  shopId,
                  description: '',
                  active: false,
              };
    const currentDeliveryIntegrationId = deliveryIntegrationId || currentShopDelivery.deliveryIntegrationId;
    const deliveryIntegration = deliveryIntegrations.find(
        (curDeliveryIntegration) => curDeliveryIntegration.id === currentDeliveryIntegrationId,
    );

    if ((itemId !== 'new' && String(shopDelivery?.id) === itemId) || itemId === 'new') {
        return (
            <Form onFinish={onSave} form={form} initialValues={currentShopDelivery}>
                <Form.Item name="id" label={t(enumFieldName.id)} {...formItemLayout}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name="title" label={t(enumFieldName.title)} rules={[{ required: true }]} {...formItemLayout}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t(enumFieldName.description)}
                    rules={[{ required: true }]}
                    {...formItemLayout}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item name="active" valuePropName="checked" label={t(enumFieldName.isActive)} {...formItemLayout}>
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    rules={[{ required: true }]}
                    name="deliveryIntegrationId"
                    label="Способ получения"
                >
                    <Select onChange={setDeliveryIntegrationId} items={deliveryIntegrations} />
                </Form.Item>
                {deliveryIntegration ? (
                    // @ts-ignore
                    <IntegrationFields fieldName="setting" infoFields={deliveryIntegration.settingFields} />
                ) : null}
                <Form.Item name="shopId" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Space>
                        <Button disabled={isSubmitting} loading={isSubmitting} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                        {error && <ErrorMessage error={error} />}
                    </Space>
                </Form.Item>
            </Form>
        );
    }
    return null;
};

export default ShopDeliveryEdit;
