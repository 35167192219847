import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { baseStaticUrl } from '../../../utils/http';
import FileUpload from './FileUpload';
import { uploadShopFile } from '../../../store/File';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { IFile } from '../../../store/Crud/types/file';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';

interface IFileList {
    shopId: number;
    onClick: (arg: IFile) => void;
}

const FileList: FC<IFileList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const shopFiles = useSelector(getCrudItems(resource.FILE));

    useEffect(() => {
        dispatch(find(resource.FILE, { shopId, type: 'document' })());
    }, [dispatch, shopId]);

    const onFileUploaded = async (file: IFile) => {
        await dispatch(uploadShopFile({ uploadFile: file, shopId, type: 'document' }));
    };

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '25%',
            render: (title: string, item: IFile) => title || `Файл #${item.id}`,
        },
        {
            title: t(enumFieldName.type),
            dataIndex: 'type',
            key: 'type',
            width: '25%',
        },
        {
            title: t(enumFieldName.url),
            dataIndex: 'path',
            key: 'path',
            width: '25%',
            render: (path: string) => (
                <a target="_blank" href={baseStaticUrl + path}>
                    Скачать
                </a>
            ),
        },
    ];

    return (
        <>
            <div key="upload" style={{ margin: '16px 0' }}>
                <FileUpload onFileUploaded={onFileUploaded} />
            </div>
            <Table
                key="table"
                rowKey="id"
                dataSource={shopFiles}
                columns={columns}
                onRow={(record) => ({
                    onClick: () => onClick(record),
                })}
            />
        </>
    );
};

export default FileList;
