import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import ShopDeliveryEdit from './components/ShopDeliveryEdit';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { getShopId } from '../../store/System/selectors';

const ShopDeliveryEditPage = () => {
    const shopDelivery = useSelector(getCrudItem(resource.SHOP_DELIVERY));
    const shopId: number = useSelector(getShopId) as any;

    const { id: shopDeliveryId } = useParams<{ id: string }>();

    const getTitle = () => {
        if (shopDeliveryId === 'new') {
            return 'Новая';
        }
        return shopDelivery ? `#${shopDelivery.id} ${shopDelivery.title}` : '';
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.shopDeliveryList}>Способы доставки</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <ShopDeliveryEdit shopId={shopId} itemId={shopDeliveryId} />
            </div>
        </div>
    );
};

export default ShopDeliveryEditPage;
