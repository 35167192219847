import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';
import { getCrudItems } from '../../../store/Crud/selectors';

const PaymentIntegrationList = () => {
    const dispatch = useAppDispatch();

    const paymentIntegrations = useSelector(getCrudItems(resource.PAYMENT_INTEGRATION));

    useEffect(() => {
        dispatch(find(resource.PAYMENT_INTEGRATION)());
    }, [dispatch]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '10%',
        },
    ];

    return <Table rowKey="id" dataSource={paymentIntegrations} columns={columns} />;
};

export default PaymentIntegrationList;
