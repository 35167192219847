import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import ProfileEdit from './components/ProfileEdit';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { get } from '../../store/Crud';
import { useAppDispatch } from '../../store';
import { getShopId } from '../../store/System/selectors';

const ProfileEditPage = () => {
    const dispatch = useAppDispatch();

    const { id: profileId } = useParams<{ id: string }>();

    const shopId = useSelector(getShopId);
    const profile = useSelector(getCrudItem(resource.PROFILE));

    useEffect(() => {
        if (profileId !== 'new') {
            dispatch(get(resource.PROFILE, profileId, { shopId })());
        }
    }, [dispatch, profileId, shopId]);

    const getTitle = () => {
        if (profileId === 'new') {
            return 'Новая';
        }
        const name = profile ? `${profile.firstName} ${profile.lastName}` : '';
        if (!name.trim()) {
            return `user #${profileId}`;
        }

        return name;
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.profileList}>Пользователи</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <ProfileEdit itemId={profileId} />
            </div>
        </div>
    );
};

export default ProfileEditPage;
