import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { IObject, ObjectView } from '../../../components/ObjectView';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { IDictionaryItem } from '../../../store/Crud/types/dictionaryItem';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';

interface IDictionaryItemList {
    dictionaryId: number;
    onClick: (arg: IDictionaryItem) => void;
}

const DictionaryItemList: FC<IDictionaryItemList> = ({ dictionaryId, onClick }) => {
    const dispatch = useAppDispatch();

    const dictionaryItems = useSelector(getCrudItems(resource.DICTIONARY_ITEM));

    useEffect(() => {
        dispatch(find(resource.DICTIONARY_ITEM, { dictionaryId, _order: { id: 'desc' } })());
    }, [dictionaryId, dispatch]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '18%',
        },
        {
            title: t(enumFieldName.data),
            dataIndex: 'data',
            key: 'data',
            render: (data: IObject) => <ObjectView object={data} />,
        },
        {
            title: t(enumFieldName.externalId),
            dataIndex: 'externalId',
            key: 'externalId',
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={dictionaryItems}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default DictionaryItemList;
