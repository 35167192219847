import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import 'antd/dist/antd.css';
import { useSelector } from 'react-redux';
import Linker from '../../components/Linker';
import { setCurrentShopId } from '../../store/System';
import { getShopId } from '../../store/System/selectors';
import { getCrudItemTitle } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { useAppDispatch } from '../../store';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import ShopMenu from './components/ShopMenu';

const ShopPage = () => {
    const dispatch = useAppDispatch();

    const { shopId: shopIdFromParams } = useParams<{ shopId: string }>();

    const shopId: number = useSelector(getShopId) as any;

    const title = useSelector(getCrudItemTitle(resource.SHOP));
    useEffect(() => {
        if ((!shopId && shopIdFromParams) || shopId !== Number(shopIdFromParams)) {
            dispatch(setCurrentShopId(shopIdFromParams === 'new' ? 'new' : Number(shopIdFromParams)));
        }
    }, [dispatch, shopId, shopIdFromParams]);

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.shopList}>Площадки</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{shopIdFromParams === 'new' ? 'Новая' : title}</Breadcrumb.Item>
            </Breadcrumb>
            <ShopMenu />
        </div>
    );
};

export default React.memo(ShopPage);
