import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import FileList from './components/FileList';
import { createFileLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { IFile } from '../../store/Crud/types/file';

const FileListPage = () => {
    const history = useHistory();
    const shopId: number = useSelector(getShopId) as any;

    const goToItem = (item: IFile) => {
        history.push(createFileLink(String(shopId), String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Файлы</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">{shopId && <FileList shopId={shopId} onClick={goToItem} />}</div>
        </div>
    );
};

export default FileListPage;
