import React from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { createLink } from '../../../components/Linker/PathCreators';
import { enumTypesLinks } from '../../../components/Linker/typesLinks';
import ShopSettingPage from './ShopSettingPage';
import CategoryListPage from '../../CategoryListPage';
import CategoryEditPage from '../../CategoryEditPage';
import GoodListPage from '../../../legacy/pages/GoodListPage';
import GoodEditPage from '../../../legacy/pages/GoodEditPage';
import GoodShopPropertyListPage from '../../../legacy/pages/GoodShopPropertyListPage';
import ShopDeliveryListPage from '../../ShopDeliveryListPage';
import ShopDeliveryEditPage from '../../ShopDeliveryEditPage';
import ShopPaymentListPage from '../../ShopPaymentListPage';
import ShopPaymentEditPage from '../../ShopPaymentEditPage';
import ShopClaimListPage from '../../ShopClaimListPage';
import ShopClaimEditPage from '../../ShopClaimEditPage';
import ShopNotificationListPage from '../../ShopNotificationListPage';
import ShopNotificationEditPage from '../../ShopNotificationEditPage';
import ManagerListPage from '../../ManagerListPage';
import ManagerEditPage from '../../ManagerEditPage';
import ShopAddressListPage from '../../ShopAddressListPage/ShopAddressListPage';
import ShopAddressEditPage from '../../ShopAddressEditPage';
import ApiUserListPage from '../../ApiUserListPage';
import ApiUserEditPage from '../../ApiUserEditPage';
import FileListPage from '../../FileListPage';
import FileEditPage from '../../FileEditPage';
import OrderListPage from '../../OrderListPage';
import OrderEditPage from '../../OrderEditPage';
import PriceListPage from '../../PriceListPage';
import PriceEditPage from '../../PriceEditPage';
import LegalPriceListPage from '../../LegalPriceListPage';
import LegalPriceEditPage from '../../LegalPriceEditPage';
import DictionaryListPage from '../../DictionaryListPage';
import DictionaryEditPage from '../../DictionaryEditPage';
import DictionaryItemEditPage from '../../DictionaryItemEditPage';
import GoodModelEditPage from '../../GoodModelEditPage';
import GoodModelListPage from '../../GoodModelListPage';
import ShopNotificationConnectionEditPage from '../../ShopNotificationConnectionEditPage';
import ShopMarketplaceSettingListPage from '../../ShopMarketplaceSettingListPage';
import ShopMarketplaceSettingEditPage from '../../ShopMarketplaceSettingEditPage';

const ShopRoutes = () => (
    <Layout>
        <Switch>
            <Route
                exact
                path={createLink(enumTypesLinks.shop, ':shopId')}
                component={() => ShopSettingPage({ isAdmin: true })}
            />
            <Route exact path={createLink(enumTypesLinks.categoryList, ':shopId')} component={CategoryListPage} />
            <Route exact path={createLink(enumTypesLinks.category, ':shopId', ':id')} component={CategoryEditPage} />
            <Route exact path={createLink(enumTypesLinks.goodList, ':shopId')} component={GoodListPage} />
            <Route exact path={createLink(enumTypesLinks.good, ':shopId', ':id')} component={GoodEditPage} />
            <Route
                exact
                path={createLink(enumTypesLinks.goodShopPropertyList, ':shopId')}
                component={GoodShopPropertyListPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopDeliveryList, ':shopId')}
                component={ShopDeliveryListPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopDelivery, ':shopId', ':id')}
                component={ShopDeliveryEditPage}
            />
            <Route exact path={createLink(enumTypesLinks.shopPaymentList, ':shopId')} component={ShopPaymentListPage} />
            <Route
                exact
                path={createLink(enumTypesLinks.shopPayment, ':shopId', ':id')}
                component={ShopPaymentEditPage}
            />
            <Route exact path={createLink(enumTypesLinks.shopClaimList, ':shopId')} component={ShopClaimListPage} />
            <Route exact path={createLink(enumTypesLinks.shopClaim, ':shopId', ':id')} component={ShopClaimEditPage} />
            <Route
                exact
                path={createLink(enumTypesLinks.shopNotificationList, ':shopId')}
                component={ShopNotificationListPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopNotification, ':shopId', ':id')}
                component={ShopNotificationEditPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopNotificationConnection, ':shopId', ':id')}
                component={ShopNotificationConnectionEditPage}
            />
            <Route exact path={createLink(enumTypesLinks.managerList, ':shopId')} component={ManagerListPage} />
            <Route exact path={createLink(enumTypesLinks.manager, ':shopId', ':id')} component={ManagerEditPage} />
            <Route exact path={createLink(enumTypesLinks.shopAddressList, ':shopId')} component={ShopAddressListPage} />
            <Route
                exact
                path={createLink(enumTypesLinks.shopAddress, ':shopId', ':id')}
                component={ShopAddressEditPage}
            />
            <Route exact path={createLink(enumTypesLinks.apiUserList, ':shopId')} component={ApiUserListPage} />
            <Route exact path={createLink(enumTypesLinks.apiUser, ':shopId', ':id')} component={ApiUserEditPage} />
            <Route exact path={createLink(enumTypesLinks.fileList, ':shopId')} component={FileListPage} />
            <Route exact path={createLink(enumTypesLinks.file, ':shopId', ':id')} component={FileEditPage} />
            <Route exact path={createLink(enumTypesLinks.orderList, ':shopId')} component={OrderListPage} />
            <Route exact path={createLink(enumTypesLinks.order, ':shopId', ':id')} component={OrderEditPage} />
            <Route exact path={createLink(enumTypesLinks.priceList, ':shopId')} component={PriceListPage} />
            <Route exact path={createLink(enumTypesLinks.price, ':shopId', ':id')} component={PriceEditPage} />
            <Route exact path={createLink(enumTypesLinks.legalPriceList, ':shopId')} component={LegalPriceListPage} />
            <Route
                exact
                path={createLink(enumTypesLinks.legalPrice, ':shopId', ':id')}
                component={LegalPriceEditPage}
            />
            <Route exact path={createLink(enumTypesLinks.dictionaryList, ':shopId')} component={DictionaryListPage} />
            <Route
                exact
                path={createLink(enumTypesLinks.dictionary, ':shopId', ':id')}
                component={DictionaryEditPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.dictionaryItem, ':shopId', ':id', ':dictionaryId')}
                component={DictionaryItemEditPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.dictionaryItemList, ':shopId', ':id')}
                component={DictionaryEditPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopModelList, ':shopId')}
                component={() => <GoodModelListPage isAdmin isShopRoute />}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopModel, ':shopId', ':id')}
                component={() => <GoodModelEditPage isAdmin isShopRoute />}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopMarketplaceSettingList, ':shopId')}
                component={ShopMarketplaceSettingListPage}
            />
            <Route
                exact
                path={createLink(enumTypesLinks.shopMarketplaceSetting, ':shopId', ':id')}
                component={ShopMarketplaceSettingEditPage}
            />
        </Switch>
    </Layout>
);

export default ShopRoutes;
