import React from 'react';
import { Breadcrumb } from 'antd';
import SettingNotificationList from './components/SettingNotificationList';

const SettingNotificationPage = () => (
    <div>
        <Breadcrumb>
            <Breadcrumb.Item>Настройки уведомлений</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
            <SettingNotificationList />
        </div>
    </div>
);

export default SettingNotificationPage;
