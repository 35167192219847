import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';
import { IDictionary } from '../../../store/Crud/types/dictionary';

interface IDictionaryList {
    shopId: number;
    onClick: (arg: IDictionary) => void;
}

const DictionaryList: FC<IDictionaryList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const items = useSelector(getCrudItems(resource.DICTIONARY));

    useEffect(() => {
        dispatch(find(resource.DICTIONARY, { shopId, _order: { path: 'asc' } })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '18%',
        },
        {
            title: t(enumFieldName.externalId),
            dataIndex: 'externalId',
            key: 'externalId',
        },
        {
            title: t(enumFieldName.alias),
            dataIndex: 'alias',
            key: 'alias',
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={items}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default DictionaryList;
