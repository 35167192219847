import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { enumResources, resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { getCrudItems } from '../../../store/Crud/selectors';

const DeliveryIntegrationList = () => {
    const dispatch = useAppDispatch();

    const deliveryIntegrations = useSelector(getCrudItems(enumResources.DELIVERY_INTEGRATION));

    useEffect(() => {
        dispatch(find(resource.DELIVERY_INTEGRATION)());
    }, [dispatch]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '10%',
        },
    ];

    return <Table rowKey="id" dataSource={deliveryIntegrations} columns={columns} />;
};

export default DeliveryIntegrationList;
