import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { getCrudItem } from '../../../store/Crud/selectors';
import { IFile } from '../../../store/Crud/types/file';
import { remove, update, get } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';

interface IFileEdit {
    shopId: number;
    itemId: string;
    onRemove: () => void;
}

const FileEdit: FC<IFileEdit> = ({ shopId, itemId, onRemove }) => {
    const shopFile = useSelector(getCrudItem(resource.FILE));
    const dispatch = useAppDispatch();

    const fields = {
        title: '',
    };

    const requiredFields = ['title'];

    const onSubmit = (item: IFile) => {
        dispatch(update(resource.FILE, String(item.id), item)());
    };

    const removeFile = (item: IFile) => {
        if (item.id) {
            dispatch(remove(resource.FILE, String(item.id))());
            onRemove();
        }
    };

    useEffect(() => {
        dispatch(get(resource.FILE, itemId, { shopId })());
    }, [itemId, shopId, dispatch]);

    if (itemId === 'new' ? false : itemId !== String(shopFile?.id)) {
        return <Loading />;
    }

    return (
        <ItemEdit
            item={shopFile}
            subjectName="file"
            fields={fields}
            onSave={onSubmit}
            onRemove={removeFile}
            deletable
            additionalFields={{ shopId }}
            requiredFields={requiredFields}
        />
    );
};

export default FileEdit;
