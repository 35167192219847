import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import Linker from '../../components/Linker';
import ApiUserList from './components/ApiUserList';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createApiUserLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { IApiUser } from '../../store/Crud/types/apiUser';

const ApiUserListPage = () => {
    const shopId: number = useSelector(getShopId) as any;
    const history = useHistory();

    const goToItem = (item: IApiUser) => {
        history.push(createApiUserLink(String(shopId), String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>API пользователи</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={enumTypesLinks.apiUser} itemId="new">
                        Создать
                    </Linker>
                </div>
                {shopId && <ApiUserList shopId={shopId} onClick={goToItem} />}
            </div>
        </div>
    );
};

export default ApiUserListPage;
