import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import Linker from '../../components/Linker';
import DictionaryList from './components/DictionaryList';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createDictionaryLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { IDictionary } from '../../store/Crud/types/dictionary';

const DictionaryListPage = () => {
    const history = useHistory();
    const shopId: number = useSelector(getShopId) as any;

    const goToItem = (item: IDictionary) => {
        history.push(createDictionaryLink(String(shopId), String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Справочники</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={enumTypesLinks.dictionary} itemId="new">
                        Создать
                    </Linker>
                </div>
                {shopId && <DictionaryList shopId={shopId} onClick={goToItem} />}
            </div>
        </div>
    );
};

export default DictionaryListPage;
