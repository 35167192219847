import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import { resource } from '../../utils/constants';
import DictionaryItemEdit from './components/DictionaryItemEdit';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createDictionaryItemLink } from '../../components/Linker/PathCreators';
import { getCrudItem } from '../../store/Crud/selectors';
import { getShopId } from '../../store/System/selectors';
import { IDictionaryItem } from '../../store/Crud/types/dictionaryItem';
import { useAppDispatch } from '../../store';
import { get } from '../../store/Crud';

const DictionaryItemEditPage = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const dictionary = useSelector(getCrudItem(resource.DICTIONARY));
    const dictionaryItem = useSelector(getCrudItem(resource.DICTIONARY_ITEM));
    const shopId: number = useSelector(getShopId) as any;

    const { id: dictionaryItemId } = useParams<{ id: string }>();
    const { dictionaryId } = useParams<{ dictionaryId: string }>();

    const onSave = (item: IDictionaryItem) => {
        if (item) {
            history.push(createDictionaryItemLink(String(shopId), String(item.id), String(item.dictionaryId)));
        }
    };

    useEffect(() => {
        dispatch(get(resource.DICTIONARY, dictionaryId, { shopId })());
    }, [dictionaryId, dispatch, shopId]);

    const getTitle = () => {
        if (dictionaryItemId === 'new') {
            return 'Новый';
        }
        return dictionaryItem ? dictionaryItem?.title : '';
    };

    if (!dictionary) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.dictionaryList}>Справочники</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.dictionaryItemList} itemId={dictionary.id}>
                        {dictionary.title}
                    </Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <DictionaryItemEdit
                    shopId={shopId}
                    dictionaryItemId={dictionaryItemId}
                    dictionaryId={dictionaryId}
                    onSave={onSave}
                />
            </div>
        </div>
    );
};

export default DictionaryItemEditPage;
