import React from 'react';
import { Breadcrumb } from 'antd';
import DeliveryTypeList from './components/DeliveryIntegrationList';

const DeliveryIntegrationListPage = () => (
    <div>
        <Breadcrumb>
            <Breadcrumb.Item>Способы доставки</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
            <DeliveryTypeList />
        </div>
    </div>
);

export default DeliveryIntegrationListPage;
