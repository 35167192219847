import React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import ProfileList from './components/ProfileList';
import { createProfileLink } from '../../components/Linker/PathCreators';
import { ICustomer } from '../../store/Crud/types/client';

const ProfileListPage = () => {
    const history = useHistory();

    const goToItem = (item: ICustomer) => {
        history.push(createProfileLink(String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Пользователи</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <ProfileList onClick={goToItem} />
            </div>
        </div>
    );
};

export default ProfileListPage;
