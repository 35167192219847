import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import { formItemLayout, resource, tailFormItemLayout } from '../../../utils/constants';
import IntegrationFields from '../../../components/ItemEdit/components/IntegrationFields';
import Select from '../../../components/ItemEdit/components/Select';
import ErrorMessage from '../../../components/ItemEdit/components/ErrorMessage';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItem, getCrudItems } from '../../../store/Crud/selectors';
import { IShopPayment } from '../../../store/Crud/types/shopPayment';
import { create, find, get, update } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { IPaymentIntegration } from '../../../store/Crud/types';

interface IShopPaymentEdit {
    shopId: number;
    itemId: string;
    actionAfterSave: (itemShopPayment: IShopPayment) => void;
}

const ShopPaymentEdit: FC<IShopPaymentEdit> = ({ itemId, shopId, actionAfterSave }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const [paymentIntegrationId, setPaymentIntegrationId] = useState(null);
    const [currentId, setCurrentId] = useState<string>();
    const [error, setError] = useState<{ code: string }>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const paymentIntegrations: IPaymentIntegration[] = useSelector(getCrudItems(resource.PAYMENT_INTEGRATION)) as any;
    const shopPayment: IShopPayment = useSelector(getCrudItem(resource.SHOP_PAYMENT)) as any;

    useEffect(() => {
        if (currentId !== 'new') {
            form.resetFields();
        }

        if (currentId !== itemId) {
            if (itemId !== 'new') {
                dispatch(get(resource.SHOP_PAYMENT, itemId, { shopId })());
            }
            dispatch(find(resource.PAYMENT_INTEGRATION)());
            setCurrentId(itemId);
        }
    }, [currentId, dispatch, form, itemId, shopId]);

    const onSave = async (itemShopPayment: IShopPayment) => {
        setIsSubmitting(true);
        if (itemShopPayment.id) {
            const { payload } = await dispatch(
                update(resource.SHOP_PAYMENT, String(itemShopPayment.id), itemShopPayment)(),
            );
            if (payload?.error?.code) {
                setError(payload?.error);
            } else {
                setError(undefined);
            }
        } else {
            const { payload } = await dispatch(create(resource.SHOP_PAYMENT, itemShopPayment)());
            if (payload?.data) {
                actionAfterSave(payload?.data);
            }
        }
        setIsSubmitting(false);
    };

    const currentShopPayment = useMemo(
        () =>
            shopPayment && String(shopPayment.id) === itemId
                ? shopPayment
                : {
                      shopId,
                      description: '',
                      active: false,
                      paymentIntegrationId: undefined,
                  },
        [itemId, shopId, shopPayment],
    );

    const currentPaymentIntegrationId = paymentIntegrationId || currentShopPayment?.paymentIntegrationId;
    const paymentIntegration = paymentIntegrations.find(
        (curPaymentIntegration) => curPaymentIntegration.id === currentPaymentIntegrationId,
    );

    if ((itemId !== 'new' && String(shopPayment?.id) === itemId) || itemId === 'new') {
        return (
            <Form onFinish={onSave} form={form} initialValues={currentShopPayment}>
                <Form.Item name="id" label={t(enumFieldName.id)} {...formItemLayout}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name="title" label={t(enumFieldName.title)} rules={[{ required: true }]} {...formItemLayout}>
                    <Input />
                </Form.Item>
                <Form.Item name="description" label={t(enumFieldName.description)} {...formItemLayout}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item name="externalId" label={t(enumFieldName.externalId)} rules={[{ required: true }]} {...formItemLayout}>
                    <Input />
                </Form.Item>
                <Form.Item name="active" valuePropName="checked" label={t(enumFieldName.isActive)} {...formItemLayout}>
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    rules={[{ required: true }]}
                    name="paymentIntegrationId"
                    label="Способ оплаты"
                >
                    <Select onChange={setPaymentIntegrationId} items={paymentIntegrations} />
                </Form.Item>
                {paymentIntegration ? (
                    // @ts-ignore
                    <IntegrationFields fieldName="setting" infoFields={paymentIntegration.settingFields} />
                ) : null}
                <Form.Item name="shopId" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Space>
                        <Button loading={isSubmitting} disabled={isSubmitting} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                        {error && <ErrorMessage error={error} />}
                    </Space>
                </Form.Item>
            </Form>
        );
    }
    return null;
};

export default ShopPaymentEdit;
