import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { ICustomer } from '../../../store/Crud/types/client';

interface IProfileList {
    onClick: (item: ICustomer) => void;
}

const ProfileList: FC<IProfileList> = ({ onClick }) => {
    const dispatch = useAppDispatch();

    const profiles = useSelector(getCrudItems(resource.PROFILE));

    useEffect(() => {
        dispatch(find(resource.PROFILE, { _order: { id: 'desc' } })());
    }, [dispatch]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.phone),
            dataIndex: 'phone',
            key: 'phone',
            width: '10%',
        },
        {
            title: t(enumFieldName.fio),
            dataIndex: 'fio',
            key: 'fio',
            width: '10%',
            render: (title: string, item: ICustomer) => `${item.firstName} ${item.lastName}`,
        },
        {
            title: t(enumFieldName.email),
            dataIndex: 'email',
            key: 'email',
            width: '10%',
        },
        {
            title: t(enumFieldName.inn),
            dataIndex: 'inn',
            key: 'inn',
            width: '10%',
        },
        {
            title: t(enumFieldName.legalName),
            dataIndex: 'legalName',
            key: 'legalName',
            width: '10%',
        },
        {
            title: t(enumFieldName.admin),
            dataIndex: 'admin',
            key: 'admin',
            width: '10%',
            render: (item: boolean) => (item ? 'Да' : 'Нет'),
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={profiles}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default ProfileList;
